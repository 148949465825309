<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Подразделения"
        :counter="$store.getters.getUnitData.data ? $store.getters.getUnitData.data.length : 0"
        :button="{ title: 'Добавить подразделение', link: '/admin/unit/create?type=create' }"
      />
    <form  class="filters">
        <select class="input blue" name="convocation" @change="changeConvocation">
          <option disabled selected>Состав</option>
          <option v-for="item in $store.getters.getConvocation"
                  :key="`convocation${item.id}`"
                  :value="item.id">{{ item.name }}
          </option>
        </select>
    </form>
      <main>
        <table v-if="$store.getters.getUnitData.data && $store.getters.getUnitData.data.length">
          <tr>
            <td><p>id</p></td>
            <td><p>Название</p></td>
            <td><p>Тип</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr v-for="item in $store.getters.getUnitData.data" :key="`getConvocation${item.id}`"
            :class="{ hidden: item.name === 'Без отдела' }">
            <td><p>{{ item.id }}</p></td>
            <td><p>{{ item.name }}</p></td>
            <td><p>{{ item.type }}</p></td>
            <td class="drop-icon">
              <drop-list @clickItem="clickItem" :idItem="item.id"/>
            </td>
          </tr>
        </table>
        <p v-else>В данном состате нету подразделов. Выбирите состав в селетке или создайте его</p>
        <pagination/>
      </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'ConvocationList',
  data () {
    return {
      filers: {
        name: ''
      },
      convocationId: null
    }
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить состав ?')
        if (consent) {
          this.$store.dispatch('deleteUnit', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailUnit', value.id)
      }
    },
    changeConvocation (e) {
      this.convocationId = e.target.value
      this.$store.dispatch('setUnit', e.target.value)
    }
  },
  components: {
    HeaderAdminPages,
    Pagination,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
}
.hidden{
  visibility: hidden;
}
</style>
